<template>
<div id="a-empresa-a2" class="content">
    <img class="main-img" src="https://i.ibb.co/gFHrNFpd/Banner-Beatrix-modified.png">
    <div id="conteudo">
        <div id="left-menu" class="inline-menu">
            <div class="current-page">{{ currentPage }}</div>
            <ul>
                <li><v-btn class="btn" to="/empresa">Quem Somos</v-btn></li>
                <li><v-btn class="btn" to="/localizacao">Localização</v-btn></li>
                <li><v-btn class="btn" to="/contato">Contato</v-btn></li>
                <li><v-btn class="btn" to="/certificados">Certificações</v-btn></li>
            </ul>
        </div>
        <div id="quem-somos" class="inline-menu">
            <h1>A empresa A2</h1>
            <p class=text>
              Nossa empresa foi fundada com a missão de tornar a experiência de crédito imobiliário excepcional para nossos clientes e parceiros. Colocamos as necessidades e os desejos dos nossos clientes no centro de cada decisão que tomamos. Nosso objetivo é não apenas oferecer soluções financeiras, mas também criar relações de longo prazo baseadas em confiança, respeito e integridade.<br><br>
              Nossos sócios acumulam muitos anos de experiência no segmento de crédito imobiliário. Essa vasta expertise nos permite oferecer um serviço sólido e eficiente aos nossos clientes, adotamos uma abordagem personalizada para cada caso, oferecendo soluções de crédito imobiliário que se adequam às necessidades específicas de nossos clientes. Conhecemos os meandros do mercado e, com essa compreensão, estamos prontos para orientar nossos clientes em suas decisões financeiras mais importantes.<br><br>
              Nossa equipe é composta por profissionais altamente qualificados e apaixonados pelo que fazem. Cada membro da equipe compartilha do mesmo compromisso em fornecer o melhor atendimento possível e entende a importância de proporcionar uma experiência positiva e tranquila durante todo o processo de aquisição de crédito imobiliário.<br><br>
              Valorizamos nossas parcerias estratégicas com corretores imobiliários, incorporadoras e outros profissionais do setor. Reconhecemos o papel fundamental que esses parceiros desempenham no processo de aquisição de imóveis e estamos comprometidos em trabalhar em estreita colaboração com eles para atingir objetivos comuns e proporcionar um serviço ainda mais completo aos nossos clientes.
            </p>
        </div>
    </div>
</div>
</template>
<script>

export default {
    data() {
        return {
            currentPage: "Home » Quem somos",
        }
    }
}
</script>
<style scoped>
.btn {
    
}

#conteudo {
    text-align: center;
}
.inline-menu {
    display: inline-block;
    padding: 1rem 1rem;
    vertical-align: top;
}

.text {
    text-align: justify;
    text-justify: distribute;
    line-height: 1.5;
}
.main-img {
    margin-top: 0px;
    width: 100%;
    height: 300px;
}

#conteudo {
    margin: 1rem;
}


#left-menu li {
    list-style: none;
    padding: 0.2rem;
    margin-bottom: 0.5rem;
}

#left-menu {

}

#left-menu a {
    text-decoration: none;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 25, 136);
    width: 350px;
    height: 60px;
}

#left-menu a:hover {
    color: white;
    background-color: rgb(255, 145, 0);
}

#quem-somos {
    width: 800px;
    text-align: left;
    margin-left: auto;
}


#quem-somos p {
    width: 80%;
    margin: 1rem;
    margin-left:0;
}

#localizacao {
    text-align: center;
    margin-top: 5rem;
}

#contato {
    text-align: center;
}

#certificacoes {
    text-align: center;
}

.current-page {
    text-align: left;
    margin-top: -2rem;
    margin-bottom: 1rem;
}

#map {
    height: 500px;
    width: 500px;
    margin: auto;
}

@media (max-width: 430px) {
    #quem-somos {
        width: 320px;
    }

    #quem-somos p {
        font-size: 16px;
        margin: auto;
        margin-bottom: 1rem;

    }

    .main-img {
        height: 140px;
    }
    #quem-somos h1 {
        font-size: 22px;
        text-align: center;
    }

    #left-menu a{
        width: 200px;
        margin: 0;
    }

    .current-page {
        text-align: center;
    }
}

</style>
