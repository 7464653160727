<template>
    <div id="a-empresa-a2" class="content">
        <img class="main-img" src="https://i.ibb.co/gFHrNFpd/Banner-Beatrix-modified.png">
        <div id="conteudo">
            <div id="left-menu" class="inline-menu">
                <div class="current-page" style="text-align: center;">{{ currentPage }}</div>
                <ul>    
                    <li><v-btn class="btn" to="/empresa">Quem Somos</v-btn></li>
                    <li><v-btn class="btn" to="/localizacao">Localização</v-btn></li>
                    <li><v-btn class="btn" to="/contato">Contato</v-btn></li>
                    <li><v-btn class="btn" to="/certificados">Certificações</v-btn></li>
                </ul>
            </div>
            <div id="certificacoes" class="inline-menu">
                <h1>Certificações</h1>
                <img :src=certificado_febraban>
                <img :src=certificado_ca600>
                <img :src=certificado_ca300>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    components: {

    },
    data() {
        return {
            currentPage: "Home » Certificações",
            certificado_febraban: "https://images.comunidades.net/des/despachanteimobiliario/logo_certificado_febraban.jpg",
            certificado_ca600: "https://www.bentocred.com.br/site/images/CA-600.png",
            certificado_ca300: "https://aprovabancarios.com/wp-content/uploads/2015/09/resolu%C3%A7%C3%A3o-bacen-correspondente-banc%C3%A1rio-04.png",
        }
    },
}
</script>
<style scoped>.btn {
    
}

#conteudo {
    text-align: center;
}
.inline-menu {
    display: inline-block;
    padding: 1rem 1rem;
    vertical-align: top;

}

.text {
    text-align: left;
    line-height: 1.5;
}
.main-img {
    margin-top: 0px;
    width: 100%;
    height: 300px;
}

#conteudo {
    margin: 1rem;
}


#left-menu li {
    list-style: none;
    padding: 0.2rem;
    margin-bottom: 0.5rem;
}

#left-menu {

}

#left-menu a {
    text-decoration: none;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 25, 136);
    width: 350px;
    height: 60px;
}

#left-menu a:hover {
    color: white;
    background-color: rgb(255, 145, 0);
}

#quem-somos {
    width: 800px;
    text-align: left;
    margin-left: auto;
}


#quem-somos p {
    width: 80%;
    margin: 1rem;
    margin-left:0;
}

#localizacao {
    text-align: center;
    margin-top: 5rem;
}

#contato {
    text-align: center;
}

#certificacoes {
    text-align: center;
}

#certificacoes h1 {
    text-align: left;
    margin-bottom: 2rem;
}

#certificacoes img {
    width: 256px;
    height: 128px;
    
}

.current-page {
    text-align: left;
    margin-top: -2rem;
    margin-bottom: 1rem;
}


@media (max-width: 430px) {
    #left-menu a {
        width: 200px;
    }    

    #left-menu {
        display: block;
        margin: auto;
        text-align: center;
    }
    .main-img {
        height: 140px;
    }
    #certificacoes h1 {
        text-align: center;
    }
    
    #certificacoes img {
        width: 150px;
        height: 75px;
    }
}

@media (max-width: 600px) {
    
}

</style>