<template>
    <div id="a-empresa-a2" class="content">
        <img class="main-img" src="https://i.ibb.co/gFHrNFpd/Banner-Beatrix-modified.png">
        <div class="conteudo">
            <div id="left-menu" class="inline-menu">
                <div class="current-page">{{ currentPage }}</div>
                <ul>
                    <li><v-btn class="btn" to="/empresa">Quem Somos</v-btn></li>
                    <li><v-btn class="btn" to="/localizacao">Localização</v-btn></li>
                    <li><v-btn class="btn" to="/contato">Contato</v-btn></li>
                    <li><v-btn class="btn" to="/certificados">Certificações</v-btn></li>
                </ul>
            </div>
            <div id="localizacao" class="inline-menu">
                <h1>Localização</h1>
                <p><b>Centro Comercial Beatrix Boulevard - Sala 713</b></p>
                <p>Av. Brasil, 600 - Boqueirão, Praia Grande - SP, 11701-090</p>
                <div id="map">
                    <iframe id=map src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14578.383812745546!2d-46.417739!3d-24.010041!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce1db678b41477%3A0x7a7ad2f7db6d5282!2sBeatrix%20Boulevard!5e0!3m2!1sen!2sbr!4v1688522778457!5m2!1sen!2sbr" width="400" height="300" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            currentPage: "Home » Localização",
        }
    },

}
</script>
<style scoped>
.btn {
    
}

#conteudo {
    text-align: center;
}
.inline-menu {
    display: inline-block;
    padding: 1rem 1rem;
    vertical-align: top;

}

.text {
    text-align: left;
    line-height: 1.5;
}
.main-img {
    margin-top: 0px;
    width: 100%;
    height: 300px;
}

#conteudo {
    margin: 1rem;
}


#left-menu li {
    list-style: none;
    padding: 0.2rem;
    margin-bottom: 0.5rem;
}


#localizacao {
    text-align: center;
    margin: auto;
}

#left-menu a {
    text-decoration: none;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 25, 136);
    width: 350px;
    height: 60px;
    margin-right: 3rem;
}

#left-menu a:hover {
    color: white;
    background-color: rgb(255, 145, 0);
}



#quem-somos p {
    width: 80%;
    margin: 1rem;
    margin-left:0;
}

#localizacao {
    text-align: left;
}

.current-page {
    text-align: left;
    margin-top: -1rem;
    margin-bottom: 1rem;
}



#map {
    height: 300px;
    width: 400px;
}

@media (max-width: 430px) {
    #left-menu a{ 
        width: 200px;
        margin: auto;
        padding: auto;
        text-align: center;
    }
    .main-img {
        height: 140px;
    }
    #left-menu {
        display: block;
        text-align: center;
    }

    .current-page {
        margin-bottom: 1rem;
        text-align: center;
    }

    #localizacao {
        width: auto;
        text-align: center;
    }

    #map {
        margin: auto;
        width: 320px;
        height: 260px;
    }
}

</style>